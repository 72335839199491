.label-send-wrapper {
    width: 10cm;
    height: 16cm;
    border: 1px #d3d3d3 solid;
    padding: 10px;
    font-size: 12px;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    .header-brand {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        

        h3 {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
        }
    }

    .shipment-wrapper {
        display: flex;
        flex-direction: column;
        font-family: sans-serif;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .shipment-service-detail {
            text-align: center;
            width: 100%;

            table {
                width: 100%;

                td {
                    padding: 5px;

                    .footer-jne-png {
                        width: 55px;
                        height: 17px;
                        background-position: 58.382% 5.882%;
                        margin: auto;
                    }
                }
            }
        }

        .barcode-shipment {
            text-align: center;
            line-height: 1;
            margin: 0;
            text-align: center;

                svg {
                    width: 60px; 
                    height: auto;  
                }

                small {
                    display: block;
                    margin-top: 5px;
                }
            
        }
    }

    .line-sparator {
        width: 100%;
        border-bottom: 2px solid #535252;
    }

    .address-wrapper {
        margin-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 0px;

        p {
            margin: 0;
            font-size: 9px;
        }
    }
    .shipment-info {
        display: flex;
        width: 100%;
        border: 2px solid #000000;

        .cell {
            flex: 1;
            text-align: center;
            padding: 8px;
            border-right: 2px solid black;
            font-size: 11px;

            &:last-child {
                border-right: none;
            }
        }
    }

    .product-section {
        margin-bottom: 5px;
        table {
            width: 100%;
            border-collapse: collapse;
            th, td {
                padding: 0; 
                text-align: left; 
                font-size: 10.5px;
            }
    
            th {
                font-weight: bold;
                border-bottom: 2px solid #1b1b1b;
            }
    
            td {
                vertical-align: top; 
            }
            th:last-child, 
            td:last-child {
            width: 60px; // Sesuaikan dengan kebutuhan
            text-align: center; // Membuatnya sejajar ke kanan
        }
        }
    }
}

.button-print {
    padding: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    background: #ffffff;
    width: 120px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        margin-right: 10px;
    }
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    .label-send-wrapper {
        page-break-after: always;
        margin-bottom: 0px;
    }
}
